import about from "./about.svg";
import arrowLeft from "./arrow-left.svg";
import arrowRight from "./arrow-right.svg";
import cross from "./cross.svg";
import fullscreen from "./fullscreen.svg";
import longArrowLeft from "./long-arrow-left.svg";
import longArrowRight from "./long-arrow-right.svg";
import warning from "./warning.svg";

const icons = {
    about,
    arrowLeft,
    arrowRight,
    cross,
    fullscreen,
    longArrowLeft,
    longArrowRight,
    warning,
};

export default icons;
