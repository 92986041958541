import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBZT10HOv1FQkT53GrwjrW3_ldIE9CpLjw",
    authDomain: "snail-3d.firebaseapp.com",
    databaseURL: "https://snail-3d-default-rtdb.firebaseio.com",
    projectId: "snail-3d",
    storageBucket: "snail-3d.appspot.com",
    messagingSenderId: "396715338408",
    appId: "1:396715338408:web:e8700b705e20659d69f09d",
    measurementId: "G-L8HDBBVPFR",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
