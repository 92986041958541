import React from "react";
import styled, { keyframes, css } from "styled-components";

const ripple = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }  
`;

const SLoader = styled.div`
    /* display: inline-block; */
    position: relative;
    width: 80px;
    height: 80px;
`;

const SChild = styled.div<{ delay?: boolean }>`
    position: absolute;
    border: 4px solid ${(p) => p.theme.colors.primary};
    opacity: 1;
    border-radius: 50%;
    animation: ${ripple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    ${(p) =>
        p.delay &&
        css`
            animation-delay: -0.5s;
        `}
`;

const Loader = () => {
    return (
        <SLoader>
            <SChild /> <SChild />
        </SLoader>
    );
};

export default Loader;
