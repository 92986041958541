import { motion } from "framer-motion";
import styled from "styled-components";
import { useIsMobileOrTablet } from "../../hooks";
import { Button, Text, SVG } from "../../components/UI";
import { ISnail } from "../../store/Snails";
import BackButton from "./BackButton";
import icons from "../../assets/icons";
import PhotoCarousel from "../../components/PhotoCarousel";
import { logSelectedFactSheet } from "../../connection/AnalyticsConnection";

const SInfoPanel = styled(motion.div)`
    display: grid;
    grid-gap: 16px;

    grid-template-rows: min-content min-content min-content auto min-content min-content min-content;

    ${(p) => p.theme.desktopQuery} {
        height: 100%;
    }

    ${(p) => p.theme.tabletQuery} {
        padding: 0 38px;
    }

    z-index: 1;
`;

const SBack = styled(motion.div)`
    display: grid;
    grid-template-columns: 48px auto;
    grid-gap: 16px;
    cursor: pointer;
    width: min-content;

    ${(p) => p.theme.tabletQuery} {
        grid-template-columns: 24px auto;
    }
`;

const SDescription = styled(motion.div)`
    display: grid;
    grid-gap: 8px;
    grid-template-rows: min-content auto;
`;

const SDescriptionText = styled.div`
    ${(p) => p.theme.desktopQuery} {
        max-height: 300px;
        overflow-y: auto;
        padding-right: 16px;
    }
`;

const SDetials = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

const SDetailHolder = styled.div`
    display: grid;
    grid-gap: 8px;
`;

const SPhotoGallery = styled(motion.div)`
    display: grid;
    grid-gap: 24px;
`;

const SDisclaimer = styled(motion.div)`
    display: grid;
    grid-template-columns: 24px auto;
    grid-gap: 16px;
`;

const vInfoPanel = {
    hidden: { transition: { staggerChildren: -0.05 } },
    show: { transition: { staggerChildren: 0.05 } },
};

export const vInfoPanelItem = {
    hidden: { x: "-840px" },
    show: { x: 0 },
};

const InfoPanel: React.FC<{ snail: ISnail }> = ({ snail }) => {
    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <SInfoPanel
            variants={vInfoPanel}
            exit="hidden"
            initial="hidden"
            animate="show"
        >
            {!isMobileOrTablet && <BackButton />}
            <Text
                variants={vInfoPanelItem}
                color="primary"
                weight="bold"
                size="H2"
            >
                {snail.name}
            </Text>
            <Text variants={vInfoPanelItem} color="dark" italic size="H4">
                {snail.scientificName}
            </Text>

            <SDescription variants={vInfoPanelItem}>
                <Text color="primary" weight="bold" size="H3">
                    Description
                </Text>
                <SDescriptionText>
                    <Text color="dark" size="H5" lineHeight="150%">
                        {snail.description}
                    </Text>
                </SDescriptionText>
            </SDescription>

            <SDetials variants={vInfoPanelItem}>
                <SDetailHolder>
                    <Text color="primary" weight="bold" size="H3">
                        Size
                    </Text>
                    <Text color="dark" size="H5">
                        {snail?.sizes?.height}
                    </Text>
                </SDetailHolder>
                <div />
                <SDetailHolder>
                    <Text color="primary" weight="bold" size="H3">
                        More info
                    </Text>
                    <Button
                        onClick={() => {
                            window.open(snail.factSheet);
                            logSelectedFactSheet(snail.name);
                        }}
                        icon={icons.arrowRight}
                    >
                        Fact sheet
                    </Button>
                </SDetailHolder>
            </SDetials>

            <SPhotoGallery variants={vInfoPanelItem}>
                <Text color="primary" weight="bold" size="H3">
                    Photo Gallery
                </Text>
                <PhotoCarousel images={snail.photoGallery ?? []} />
            </SPhotoGallery>

            {snail.category === "not-yet-present" && (
                <SDisclaimer variants={vInfoPanelItem}>
                    <SVG image={icons.warning} color="primary" contain />
                    <Text color="dark" size="H5">
                        If you find these snails on your property, please
                        contact{" "}
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                window.open(
                                    "https://www.pir.sa.gov.au/biosecurity/plant_health/emergency_and_significant_plant_pests"
                                )
                            }
                        >
                            {" "}
                            Biosecurity SA
                        </a>{" "}
                        or call your <a> Exotic Pest Hotline </a>.
                    </Text>
                </SDisclaimer>
            )}
        </SInfoPanel>
    );
};

export default InfoPanel;
