import React from "react";
import styled from "styled-components";
import { useIsMobileOrTablet } from "../../../hooks";
import { Text, Toggle } from "../../../components/UI";
import { IControls } from "./logic";

const SControls = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;

    height: min-content;
    width: min-content;

    ${(p) => p.theme.desktopQuery} {
        position: absolute;
        bottom: 48px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const Controls: React.FC<IControls> = ({ coin, slug, hotspots }) => {
    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <SControls>
            {!isMobileOrTablet && (
                <Text color="light" size="H5" middle>
                    View
                </Text>
            )}
            <Toggle
                active={slug.value}
                onClick={() => slug.set((p) => !p)}
                title="Slug"
            />
            <Toggle
                active={coin.value}
                onClick={() => coin.set((p) => !p)}
                title="Coin"
            />
            <Toggle
                active={hotspots.value}
                onClick={() => hotspots.set((p) => !p)}
                title="Hotspots"
            />
        </SControls>
    );
};

export default Controls;
