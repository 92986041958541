import React, { useRef, useEffect, FC, useState } from "react";
import styled, { css } from "styled-components";
import useSnailStore from "../store/Snails";
import SnailCarouselCard from "./SnailCarouselCard";
import { motion, MotionProps, animate, clamp } from "framer-motion";
import { Text } from "./UI";

const SCarousel = styled(motion.div)`
    height: 392px; // Defines the carousel height.
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */

    /* ::-webkit-scrollbar {
        display: none; // Chrome.
    } */
`;

const SGrid = styled(motion.div)<{ main?: boolean }>`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;

    ${(p) =>
        p.main &&
        css`
            ${(p) => p.theme.desktopQuery} {
                padding: 0 64px;
            }
        `}
`;

const SRow = styled(motion.div)`
    width: min-content;
    display: grid;
    grid-gap: 32px;
`;

const SRowLine = styled.div`
    margin: 0 24px;
    border-top: 1px solid ${(p) => p.theme.colors.dark};
    padding-top: 8px;
    opacity: 0.5;
    z-index: -1;
`;

interface IProps extends MotionProps {
    next: number;
    prev: number;
}

const SnailCarousel: FC<IProps> = (props) => {
    const { next, prev } = props;
    const snailDictionary = useSnailStore((state) => state.snails);

    function getSnails() {
        const snails = Object.values(snailDictionary);

        const established = snails.filter((s) => s.category === "established");
        const notYetPresent = snails.filter(
            (s) => s.category === "not-yet-present"
        );

        return [
            <SRow key="established">
                <SGrid>
                    {established.map((snail, i) => (
                        <SnailCarouselCard
                            key={`${snail.id}_${i}`}
                            {...snail}
                        />
                    ))}
                </SGrid>
                <SRowLine>
                    <Text italic size="H4" color="dark">
                        Established
                    </Text>
                </SRowLine>
            </SRow>,
            <SRow key="not-yet-present">
                <SGrid>
                    {notYetPresent.map((snail, i) => (
                        <SnailCarouselCard
                            key={`${snail.id}_${i}`}
                            {...snail}
                        />
                    ))}
                </SGrid>
                <SRowLine>
                    <Text italic size="H4" color="dark">
                        Threat
                    </Text>
                </SRowLine>
            </SRow>,
        ];
    }

    const slide = (direction: 1 | -1) => {
        const carousel = document.getElementById("mobileCarousel");
        if (!carousel) return;

        const from = carousel.scrollLeft;
        let to = clamp(
            0,
            carousel.scrollWidth - carousel.clientWidth,
            from + 296 * 4 * direction
        );

        animate(from, to, {
            duration: 0.5,
            onUpdate: (latest) => (carousel.scrollLeft = latest),
        });
    };

    const slideNext = () => slide(1);
    const slidePrev = () => slide(-1);

    useEffect(slideNext, [next]);
    useEffect(slidePrev, [prev]);

    const snails = getSnails();

    return (
        <SCarousel {...props} id="mobileCarousel">
            <SGrid main>{snails}</SGrid>
        </SCarousel>
    );
};

export default SnailCarousel;
