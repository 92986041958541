import React, { FC, PropsWithChildren, useEffect } from "react";
import { logAnalyticsEvent } from "../firebase/analytics";
import useSnailStore from "../store/Snails";
import { insightsLog } from "../submodules/insights";
import { useLocation } from "react-router-dom";
import { useRouter } from "../hooks";

const AnalyticsConnection = React.createContext({});

function insightsLogWithConfig(eventName: string, data?: any) {
    insightsLog(
        {
            baseBody: {
                st_aid: "Snails 3D Gallery",
                st_eid: "Snails 3D Gallery",
            },
            appKey: "v1.LZDZNGCEGYBWKYZVKWVDOKZOVYZUPK",
        },
        eventName,
        data
    );
}

function logPageView(page: string): void {
    logAnalyticsEvent("pages", { page }); // GA.
}

function logSelectedSnail(
    snail: string,
    itl_na: "established" | "not-yet-present"
): void {
    logAnalyticsEvent("snails", { snail }); // GA.

    insightsLogWithConfig("Snail Selection", {
        itl_na,
        it_na: snail,
    });
}

export function logSelectedFactSheet(snail: string): void {
    logAnalyticsEvent("fact_sheet", { snail }); // GA.

    insightsLogWithConfig("Fact Sheet View", {
        itl_na: snail,
    });
}

export function logSelectedHotspot(snail: string, hotspot: string): void {
    logAnalyticsEvent("select_hotspot", { snail, hotspot });

    insightsLogWithConfig("hotspot view", {
        itl_na: snail,
        it_na: hotspot,
    });
}

function appOpen(): void {
    insightsLogWithConfig("session_start");
}

function appClose(): void {
    insightsLogWithConfig("session_end");
}

export const AnalyticsConnectionContext: FC<PropsWithChildren> = (props) => {
    const location = useLocation();
    const router = useRouter();

    const snails = useSnailStore((s) => s.snails);

    useEffect(() => {
        appOpen();
        window.addEventListener("beforeunload", appClose);

        return () => window.removeEventListener("beforeunload", appClose);
    }, []);

    useEffect(() => logPageView(location.pathname), [location.pathname]);

    useEffect(() => {
        const snail = snails?.[router.getParam("snail")];
        if (snail) logSelectedSnail(snail.name, snail.category);
    }, [location.search]);

    return (
        <AnalyticsConnection.Provider value={{}}>
            {props.children}
        </AnalyticsConnection.Provider>
    );
};

export default AnalyticsConnection;
