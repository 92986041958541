import React, { FC, useRef } from "react";
import styled, { css } from "styled-components";
import { ISnail } from "../store/Snails";
import { Image, SVG, Text } from "./UI";
import { motion } from "framer-motion";
import icons from "../assets/icons";
import { useIsMobileOrTablet, useRouter } from "../hooks";
import { Paths } from "../context/RouterContext";

const SThumbnail = styled.div`
    height: 184px;
    height: 200px;
    width: 120%;
    left: -10%;
    top: 50%;
    position: absolute;

    /* border: 2px solid red; */
`;

const SThumbnailHolder = styled.div`
    position: relative;
    width: 100%;
`;

const SBackground = styled.div`
    background-color: ${(p) => p.theme.colors.light};
    width: 232px;
    height: 208px;
    border-radius: 24px;
    padding: 24px;
    margin-top: auto;

    display: grid;
    grid-template-rows: auto min-content;
`;

const SViewDetails = styled.div`
    z-index: -1;
    position: absolute;
    bottom: -10px;
    left: 48px;

    display: grid;
    grid-template-columns: min-content 0;
    grid-gap: 16px;

    transition: 0.4s;
    opacity: 0;
`;

const HSnailCarouselCard = css`
    transform: scale(1.3);

    ${SViewDetails} {
        opacity: 1;
        transform: translateY(100%);
        grid-template-columns: min-content 56px;
    }

    ${SBackground} {
        background-color: ${(p) => p.theme.colors.primary};
        background: linear-gradient(
            180deg,
            ${(p) => p.theme.gradients.primary.a} 0%,
            ${(p) => p.theme.gradients.primary.b} 100%
        );

        * {
            color: ${(p) => p.theme.colors.light};
        }
    }
`;

const SSnailCarouselCard = styled(motion.div)<{ isSelected: boolean }>`
    height: 280px;
    width: 280px;

    display: grid;
    justify-items: center;
    position: relative;

    transition: 0.3s;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    cursor: pointer;

    ${(p) => p.theme.desktopQuery} {
        :hover {
            ${HSnailCarouselCard}
        }
    }

    ${(p) => p.isSelected && HSnailCarouselCard}
`;

const SnailCarouselCard: FC<ISnail> = (snail) => {
    const ref = useRef();
    const isMobileOrTablet = useIsMobileOrTablet();
    const router = useRouter();

    return (
        <SSnailCarouselCard
            //@ts-ignore
            ref={ref}
            onClick={() => {
                router.setParam("snail", snail.id);
                router.setPath(Paths.snailDetails, true);
            }}
            isSelected={false}
        >
            <SThumbnailHolder>
                <SThumbnail>
                    <Image image={snail.thumbnail} contain />
                </SThumbnail>
            </SThumbnailHolder>
            <SBackground>
                <div />
                <Text
                    size={isMobileOrTablet ? "H25" : "H3"}
                    weight="bold"
                    color="primary"
                >
                    {snail.name}
                </Text>
            </SBackground>

            <SViewDetails>
                <Text color="primary" size="H5" oneline weight="bold">
                    View Details
                </Text>
                <SVG image={icons.longArrowRight} contain color="primary" />
            </SViewDetails>
        </SSnailCarouselCard>
    );
};

export default SnailCarouselCard;
