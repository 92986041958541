import "styled-components";
import { DefaultTheme } from "styled-components";
import deepcopy from "deepcopy";

export interface IThemeFontSizes {
    H1: string;
    H2: string;
    H25: string;
    H3: string;
    H4: string;
    H5: string;
}

export interface IThemeColors {
    primary: string;
    background: string;
    light: string;
    medium: string;
    dark: string;
}

export interface IThemeGradients {
    primary: {
        a: string;
        b: string;
    };
}

declare module "styled-components" {
    export interface DefaultTheme {
        colors: IThemeColors;
        gradients: IThemeGradients;
        fontSize: IThemeFontSizes;
        tabletQuery: string;
        mobileQuery: string;
        desktopQuery: string;
    }
}

const Theme: DefaultTheme = {
    colors: {
        primary: "#339D4A",
        background: "#F2F2F2",
        light: "#FFFFFF",
        medium: "#D9D9D9",
        dark: "#4A5148",
    },
    gradients: {
        primary: {
            a: "#339D4A",
            b: "#0E7424",
        },
    },
    fontSize: {
        H1: "70px",
        H2: "64px",
        H25: "32px",
        H3: "24px",
        H4: "20px",
        H5: "16px",
    },
    tabletQuery: "@media only screen and (max-width: 1200px)",
    mobileQuery: "@media only screen and (max-width: 480px)",
    desktopQuery: "@media only screen and (min-width: 1200px)",
};

export const MobileTheme = deepcopy(Theme);

// Mobile Configuration.
{
    MobileTheme.fontSize.H1 = "48px";
    MobileTheme.fontSize.H2 = "40px";
    MobileTheme.fontSize.H25 = "24px";
    MobileTheme.fontSize.H3 = "16px";
    MobileTheme.fontSize.H4 = "14px";
    MobileTheme.fontSize.H5 = "12px";
}

export const TabletTheme = deepcopy(Theme);

// Table Configuration.
{
}

export default Theme;
