export const repeatArray = (arr: any[], repeats: number) =>
    Array.from({ length: repeats }, () => arr).flat();

export const mapRange = (
    value: number,
    inMin: number,
    inMax: number,
    outMin: number,
    outMax: number
) => ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

export const arrayOfLenght = (length: number) => {
    let array: number[] = [];
    for (let i = 0; i < length; i++) array.push(i);
    return array;
};
