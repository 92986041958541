import { useState } from "react";

export interface IControls {
    coin: {
        value: boolean;
        set: React.Dispatch<React.SetStateAction<boolean>>;
    };
    slug: {
        value: boolean;
        set: React.Dispatch<React.SetStateAction<boolean>>;
    };
    hotspots: {
        value: boolean;
        set: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

const useControls = () => {
    const [showCoin, setShowCoin] = useState(false);
    const [showSlug, setShowSlug] = useState(true);
    const [showHotspots, setShowHotSpots] = useState(true);

    const controls: IControls = {
        coin: { value: showCoin, set: setShowCoin },
        slug: { value: showSlug, set: setShowSlug },
        hotspots: { value: showHotspots, set: setShowHotSpots },
    };

    return controls;
};

export default useControls;
