import styled from "styled-components";
import useSnailStore from "../../store/Snails";
import { motion } from "framer-motion";
import { useIsMobileOrTablet, useRouter } from "../../hooks";
import InfoPanel from "./InfoPanel";
import GreenPanel from "./GreenPanel";
import Logos from "../../components/Logos";

const SSnailDetails = styled(motion.div)`
    display: grid;
    align-items: center;

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    ${(p) => p.theme.desktopQuery} {
        /* Fix annoying overflow */
        * {
            min-height: 0;
        }
    }

    ${(p) => p.theme.tabletQuery} {
        overflow-y: auto;
    }
`;

const SLayout = styled.div`
    display: grid;
    grid-template-columns: 440px auto;
    padding: 64px 0 38px 96px;
    height: 100%;
`;

const SMobileLayout = styled.div`
    display: grid;
`;

function SnailDetails() {
    const router = useRouter();
    const snailId = router.getParam("snail");
    const snail = useSnailStore((state) => state.snails[snailId]);
    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <SSnailDetails>
            {snail &&
                (isMobileOrTablet ? (
                    <SMobileLayout>
                        <GreenPanel snail={snail} />
                        <InfoPanel snail={snail} />
                    </SMobileLayout>
                ) : (
                    <SLayout>
                        <Logos />
                        <InfoPanel snail={snail} />
                        <GreenPanel snail={snail} />
                    </SLayout>
                ))}
        </SSnailDetails>
    );
}

export default SnailDetails;
