import React, { FC, PropsWithChildren } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Router = React.createContext({});

export enum Paths {
    home = "/snails",
    snailDetails = "/snail-deatils",
    about = "/",
}

export type TParam = "snail";

export interface IRouter {
    setPath(path: Paths, keepParams?: boolean): void;
    setParam(param: TParam, value?: string): void;
    getParam(param: TParam): string;
    back(): void;
}

export const RouterContext: FC<PropsWithChildren> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const value: IRouter = {
        setPath(path, keepPrams = false) {
            let pathWithParams: string = path;
            if (keepPrams) pathWithParams += window.location.search;
            navigate(pathWithParams);
        },
        setParam(param, value) {
            try {
                const params = new URLSearchParams(location.search);
                if (value) params.set(param, value.toString());
                else params.delete(param);

                navigate(`${location.pathname}?${params.toString()}`);
            } catch {}
        },
        getParam(param) {
            try {
                return new URLSearchParams(location.search).get(param) ?? "";
            } catch {
                return "";
            }
        },
        back() {
            navigate(-1);
        },
    };

    return <Router.Provider value={value}>{props.children}</Router.Provider>;
};

export default Router;
