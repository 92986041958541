import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { arrayOfLenght, mapRange } from "../utils";

const SPageControls = styled(motion.div)`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    align-items: center;
`;

const SLine = styled(motion.div)<{ active: boolean }>`
    border-radius: 4px;
    background-color: ${(p) =>
        p.active ? p.theme.colors.primary : p.theme.colors.medium};
    width: ${(p) => (p.active ? "72px" : "32px")};
    height: 8px;
    transition: 0.3s;

    ${(p) => p.theme.tabletQuery} {
        width: ${(p) => (p.active ? "32px" : "12px")};
    }
`;

const PageControls: React.FC<{ carouselId: string }> = ({ carouselId }) => {
    const [position, setPosition] = useState(0);
    const [requiredItems, setRequiredItems] = useState(0);

    useEffect(() => {
        const carousel = document.getElementById(carouselId);
        if (!carousel) return;

        const onScroll = (e: Event) => {
            const requiredItems = Math.ceil(
                carousel.scrollWidth / carousel.clientWidth
            );

            const scrollableWidth = carousel.scrollWidth - carousel.clientWidth;

            setRequiredItems(requiredItems);

            setPosition(
                Math.floor(
                    mapRange(
                        carousel.scrollLeft,
                        0,
                        scrollableWidth,
                        0,
                        requiredItems
                    )
                )
            );
        };

        carousel.addEventListener("scroll", onScroll);

        return () => carousel?.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <SPageControls>
            {arrayOfLenght(requiredItems).map((value) => (
                <SLine
                    active={
                        value === position ||
                        (position === requiredItems &&
                            value === requiredItems - 1)
                    }
                />
            ))}
        </SPageControls>
    );
};

export default PageControls;
