import { FC, PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import { IThemeColors, IThemeFontSizes } from "../theme";
import styled, { css, CSSProperties } from "styled-components";
import { motion, MotionProps } from "framer-motion";

//#region Text
interface ITextProps extends MotionProps {
    size?: keyof IThemeFontSizes;
    color?: keyof IThemeColors;
    weight?: "medium" | "bold";
    oneline?: boolean;
    selected?: boolean;
    onClick?(): void;
    noBorder?: boolean;
    center?: boolean;
    middle?: boolean;
    style?: CSSProperties;
    italic?: boolean;
    lineHeight?: string;
}

const SText = styled(motion.div)<ITextProps>`
    // Size.
    font-size: ${(p) => p.theme.fontSize[p.size ?? "H5"]};
    // One line.
    white-space: ${(p) => (p.oneline ? "nowrap" : "pre-wrap")};

    // Center.
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}

    // Middle.
    ${(p) =>
        p.middle &&
        css`
            margin: auto;
        `}

    // Color.
    ${(p) =>
        p.color &&
        css`
            color: ${p.theme.colors[p.color]};
        `}

    // Weight.
    ${(p) =>
        p.weight === "medium" &&
        css`
            font-weight: 500;
        `}

    ${(p) =>
        p.weight === "bold" &&
        css`
            font-family: noah-bold;
        `}

    // Italic.
    ${(p) =>
        p.italic &&
        css`
            font-family: noah-italic;
        `}

    // Interactable.
    ${(p) =>
        p.onClick &&
        css`
            cursor: pointer;
            :hover {
                color: ${(p) => p.theme.colors.primary};
            }
        `}
    
    // Is Selected.
    ${(p) =>
        p.selected &&
        css`
            color: ${(p) => p.theme.colors.primary};
        `}
    
    line-height: ${(p) => p.lineHeight ?? "100%"};

    a {
        color: ${(p) => p.theme.colors.primary};
    }
`;

export const Text: FC<ITextProps> = (props) => {
    return <SText {...props}>{props.children}</SText>;
};

//#endregion

//#region Button
interface IButtonProps extends PropsWithChildren {
    onClick?(): void;
    style?: CSSProperties;
    icon?: string;
    big?: boolean;
}

const SButton = styled(motion.div)<IButtonProps>`
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    width: min-content;

    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }

    :active {
        transform: scale(0.9);
    }

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    background-color: ${(p) => p.theme.colors.primary};

    ${(p) =>
        p.icon &&
        css`
            display: grid;
            grid-template-columns: auto 16px;
            grid-gap: 16px;
            padding: 10px;
        `}
`;

export const Button: FC<IButtonProps> = (props) => {
    return (
        <SButton {...props}>
            <Text
                style={{ width: "100%" }}
                size={props.big ? "H3" : "H5"}
                color="light"
                oneline
                weight="bold"
                center={props.big}
            >
                {props.children}
            </Text>
            {props.icon && <SVG color="light" image={props.icon} contain />}
        </SButton>
    );
};
//#endregion

//#region Image
const SImageContainer = styled(motion.div)<IImageProps>`
    height: 100%;
    border-radius: ${(p) => (p.round ? "10px" : 0)};
    overflow: hidden;
`;

const SImage = styled(motion.div)<IImageProps>`
    background-image: url(${(p) => p.image}), url(${(p) => p.placeholder});
    background-size: ${(p) =>
        p.contain ? "contain" : p.fit ? "100% 100%" : "cover"};
    background-repeat: no-repeat;
    background-position: ${(p) => p.imagePosition ?? "center"};
    height: 100%;
`;

interface IImageProps extends MotionProps {
    url?: string;
    image?: string;
    placeholder?: string;
    contain?: boolean;
    fit?: boolean;
    round?: boolean;
    style?: CSSProperties;
    imagePosition?: string;
    children?: ReactNode;
}

export const Image: FC<IImageProps> = (props) => {
    return (
        <SImageContainer {...props}>
            <SImage {...props}>{props.children}</SImage>
        </SImageContainer>
    );
};
//#endregion

//#region SVG
interface ISVGProps extends MotionProps {
    image: string;
    contain?: boolean;
    color?: keyof IThemeColors;
}

export const SVG = styled(motion.div)<ISVGProps>`
    mask-image: url(${(p) => p.image});
    mask-size: ${(p) => (p.contain ? "contain" : "cover")};
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${(p) => p.theme.colors?.[p.color ?? "light"]};
    height: 100%;
`;
//#endregion

//#region Inputfield
const SInputField = styled.input<IInputFieldProps>`
    background: none;
    font-size: ${(p) => p.theme.fontSize.H5};
    ::placeholder {
        color: ${(p) => `${p.theme.colors.dark}80`};
    }
    font-weight: 500;
    color: ${(p) => p.theme.colors.light};
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}
    width: 100%;
    height: 100%;
    border: none;
    padding: 15px;
    display: grid;
`;

const SInputFieldContainer = styled.div<{ disabled?: boolean; icon?: string }>`
    box-sizing: border-box;
    height: 48px;
    position: relative;
    background: none;

    ${(p) =>
        !p.disabled &&
        css`
            :hover {
                filter: brightness(98%);
            }
        `}

    ${(p) =>
        p.icon &&
        css`
            ${SVG} {
                position: absolute;
                left: 0;
                width: 1.875rem;
                height: 1.875rem;
            }

            ${SInputField} {
                padding-left: 1.875rem;
            }
        `}
`;

const SInputFieldWithTitle = styled.div`
    display: grid;
    grid-gap: 10px;
    height: min-content;
`;

interface IInputFieldProps extends PropsWithChildren {
    autoSelect?: boolean;
    center?: boolean;
    placeholder?: string;
    value: string;
    onChange(v: string): void;
    onEnter?(): void;
    type?: string;
    icon?: string;
    style?: CSSProperties;
    title?: string;
    disabled?: boolean;
    secondGradient?: boolean;
}

export const InputField: FC<IInputFieldProps> = (props) => {
    const inputRef = useRef();

    // Select inputfield if props.autoSelect is true.
    useEffect(() => {
        if (props.autoSelect && inputRef.current)
            (inputRef.current as any).select();
    }, [inputRef.current]);

    let content = (
        <SInputFieldContainer disabled={props.disabled} icon={props.icon}>
            {props.icon && <SVG image={props.icon} />}
            <SInputField
                //@ts-ignore
                ref={inputRef}
                {...props}
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && props.onEnter) props.onEnter();
                }}
                value={props.value ? props.value : ""}
                onChange={
                    props.disabled
                        ? () => {}
                        : (e: any) => props.onChange(e.target.value)
                }
                type={props.type ? props.type : "text"}
            />
        </SInputFieldContainer>
    );

    // Add title text if contains.
    if (props.title)
        content = (
            <SInputFieldWithTitle>
                <Text size="H4" oneline color="light">
                    {props.title}
                </Text>
                {content}
            </SInputFieldWithTitle>
        );

    return content;
};
//#endregion

//#region TextArea
const STextArea = styled.textarea<{ center?: boolean }>`
    background: none;
    outline: none;
    border: none;
    height: 320px;
    width: 100%;
    font-size: ${(p) => p.theme.fontSize.H5};
    padding: 15px;
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}

    color: ${(p) => p.theme.colors.light};

    :hover {
        filter: brightness(98%);
    }

    resize: none;
`;

interface ITextAreaProps {
    autoSelect?: boolean;
    center?: boolean;
    placeholder?: string;
    value: string;
    onChange(v: string): void;
    onEnter?(): void;
    type?: string;
    style?: CSSProperties;
    rows?: number;
    cols?: number;
    title?: string;
    secondGradient?: boolean;
}

export const TextArea: FC<ITextAreaProps> = (props) => {
    const inputRef = useRef();

    // Select inputfield if props.autoSelect is true.
    useEffect(() => {
        if (props.autoSelect && inputRef.current)
            (inputRef.current as any).select();
    }, [inputRef.current]);

    let content = (
        <STextArea
            //@ts-ignore
            ref={inputRef}
            {...props}
            value={props.value ? props.value : ""}
            onChange={(e) => props.onChange(e.target.value)}
            onKeyDown={(e) => {
                if (e.keyCode === 13 && props.onEnter) props.onEnter();
            }}
        />
    );

    // Add title text if contains.
    if (props.title)
        content = (
            <SInputFieldWithTitle>
                <Text size="H4" oneline color="light">
                    {props.title}
                </Text>
                {content}
            </SInputFieldWithTitle>
        );

    return content;
};
//#endregion

//#region IconButton
interface IIconButtonProps extends MotionProps {
    image: string;
    big?: boolean;
    onClick?(): void;
}

const SIconButton = styled(motion.div)<IIconButtonProps>`
    width: 32px;
    height: 32px;
    border-radius: 8px;
    padding: 10px;

    ${(p) =>
        p.big &&
        css`
            width: 72px;
            height: 72px;
            padding: 24px;
            border-radius: 16px;
        `}
    cursor: pointer;

    background-color: ${(p) => p.theme.colors.primary};
`;

export const IconButton: FC<IIconButtonProps> = (props) => {
    return (
        <SIconButton
            whileHover={{
                scale: 1.1,
            }}
            whileTap={{
                scale: 0.9,
            }}
            transition={{ scale: { duration: 0.3, type: "spring" } }}
            {...props}
        >
            <SVG image={props.image} contain />
        </SIconButton>
    );
};
//#endregion

//#region Toggle
const SToggle = styled(motion.div)<{ active?: boolean }>`
    width: 48px;
    height: 32px;
    cursor: pointer;
    background-color: ${(p) =>
        p.active ? p.theme.colors.light : p.theme.colors.medium};
    border-radius: 16px;

    display: grid;
    place-items: center;
    padding: 2px;
    justify-content: ${(p) => (p.active ? "right" : "left")};

    * {
        background-color: ${(p) =>
            p.active ? p.theme.colors.primary : p.theme.colors.light};
    }

    ${(p) => p.theme.mobileQuery} {
        width: 30px;
        height: 20px;
    }
`;

const SToggleHandler = styled(motion.div)`
    border-radius: 50%;
    width: 28px;
    height: 28px;

    ${(p) => p.theme.mobileQuery} {
        width: 17px;
        height: 17px;
    }
`;

const SCheckbox = styled.div<{ active?: boolean }>`
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    background-color: ${(p) =>
        p.active ? p.theme.colors.primary : p.theme.colors.light};
    border-radius: 0.156rem;
    border: 0.063rem solid ${(p) => p.theme.colors.primary};
    box-sizing: border-box;
    display: grid;
    place-items: center;

    :hover {
        filter: brightness(120%);
    }
`;

const SCheck = styled.div`
    display: inline-block;
    transform: translateY(-8%) rotate(45deg);
    height: 60%;
    width: 20%;
    border-bottom: 0.12rem solid ${(p) => p.theme.colors.light};
    border-right: 0.12rem solid ${(p) => p.theme.colors.light};
`;

const SToggleContainer = styled.div<{ disabled?: boolean }>`
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: 5px;
    width: min-content;

    ${(p) =>
        p.disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

interface IToggle {
    active?: boolean;
    onClick(): void;
    type?: "checkbox" | "toggle";
    title?: string;
    disabled?: boolean;
}

export const Toggle: FC<IToggle> = (props) => {
    const types = {
        toggle: (
            <SToggle
                {...props}
                whileTap={{ scale: 1.1, transition: { duration: 0 } }}
            >
                <SToggleHandler
                    layout
                    transition={{ duration: 0.4, type: "spring", bounce: 0.5 }}
                />
            </SToggle>
        ),
        checkbox: (
            <SCheckbox {...props}>{props.active && <SCheck />}</SCheckbox>
        ),
    };

    const toggle = types[props.type ?? "toggle"];

    if (props.title)
        return (
            <SToggleContainer disabled={props.disabled}>
                {toggle}
                <Text oneline weight="bold" size="H5" color="light">
                    {props.title}
                </Text>
            </SToggleContainer>
        );

    return toggle;
};
//#endregion
