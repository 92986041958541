import React, { FC, PropsWithChildren, useEffect } from "react";

// Stores.
import useSnailStore from "../store/Snails";
import { read } from "../firebase/database";

const Store = React.createContext({});

export const StoreContext: FC<PropsWithChildren> = (props) => {
    const snailStore = useSnailStore();

    // Just init states on awake.
    useEffect(() => {
        read("snail").then(snailStore.setSnails); // Reads snails from database, and sets zustand state.
    }, []);

    return <Store.Provider value={{}}>{props.children}</Store.Provider>;
};

export default Store;
