import React, { useState, FC } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { IconButton, Image } from "./UI";
import icons from "../assets/icons";
import useGallery from "../store/Gallery";
import { useIsMobileOrTablet } from "../hooks";

const SPhotoCarousel = styled.div`
    display: grid;
    grid-template-rows: 99px 32px;
    grid-gap: 16px;
    max-width: 440px;
`;

const SImagesHolder = styled.div`
    position: relative;
    overflow: hidden;
`;

const SImages = styled(motion.div)`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
`;

const SControls = styled.div`
    display: grid;
    grid-template-columns: min-content min-content auto min-content;
    grid-gap: 8px;
`;

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
};

const swipeConfidenceThreshold = 1000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

const PhotoCarousel: FC<{ images: string[] }> = ({ images }) => {
    const [[page, direction], setPage] = useState([0, 0]);
    const openGallery = useGallery((s) => s.open);
    const isMobileOrTablet = useIsMobileOrTablet();

    function getPages() {
        const pages: string[][] = [];

        for (let i = 0; i < images.length; i += 3) {
            const page: string[] = [];

            for (let x = i; x < i + 3; x++) {
                if (images[x]) page.push(images[x]);
            }

            pages.push(page);
        }

        return pages;
    }

    const pages = getPages();

    const pageIndex = wrap(0, pages.length, page);

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    return (
        <SPhotoCarousel>
            <SImagesHolder>
                <AnimatePresence initial={false} custom={direction}>
                    <SImages
                        key={page}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 },
                        }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -swipeConfidenceThreshold) paginate(1);
                            else if (swipe > swipeConfidenceThreshold)
                                paginate(-1);
                        }}
                    >
                        {(pages[pageIndex] ?? []).map((image, i) => (
                            <Image
                                style={{ cursor: "pointer" }}
                                whileHover={{
                                    scale: 0.98,
                                    filter: "brightness(1.2)",
                                }}
                                whileTap={{ scale: 0.8 }}
                                key={image}
                                image={image}
                                contain
                                onTap={() => {
                                    openGallery(images, pageIndex + i);
                                }}
                            />
                        ))}
                    </SImages>
                </AnimatePresence>
            </SImagesHolder>
            {!isMobileOrTablet && images?.length > 3 && (
                <SControls>
                    <IconButton
                        image={icons.arrowLeft}
                        onClick={() => paginate(-1)}
                    />
                    <IconButton
                        image={icons.arrowRight}
                        onClick={() => paginate(1)}
                    />
                    <div />
                </SControls>
            )}
        </SPhotoCarousel>
    );
};

export default PhotoCarousel;
