import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { StoreContext } from "./context/StoreContext";
import { AnimatePresence, MotionConfig } from "framer-motion";

import { Routes, Route, BrowserRouter } from "react-router-dom";

// Pages.
import Home from "./pages/Home";
import SnailDetails from "./pages/SnailDetails";
import About from "./pages/About";

import PhotoCarouselFullscreen from "./components/PhotoCarouselFullscreen";
import HotspotInfoPanel from "./components/HotspotInfoPanel";
import { Paths, RouterContext } from "./context/RouterContext";
import { AnalyticsConnectionContext } from "./connection/AnalyticsConnection";
import Logos from "./components/Logos";

const Layout: FC = () => {
    return (
        <BrowserRouter>
            <RouterContext>
                <AnalyticsConnectionContext />

                <SBackground>
                    <MotionConfig transition={{ duration: 0.5 }}>
                        <AnimatePresence initial={false}>
                            <Routes>
                                <Route
                                    path={Paths.home}
                                    element={<Home key="home" />}
                                />
                                <Route
                                    path={Paths.about}
                                    element={<About key="about" />}
                                />
                                <Route
                                    path={Paths.snailDetails}
                                    element={
                                        <SnailDetails key="snail-details" />
                                    }
                                />
                            </Routes>
                        </AnimatePresence>
                    </MotionConfig>
                </SBackground>

                {/* Overlays */}
                <PhotoCarouselFullscreen />
                <HotspotInfoPanel />
            </RouterContext>
        </BrowserRouter>
    );
};

const SBackground = styled.div`
    background-color: ${(p) => p.theme.colors.background};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
`;

const Context: FC<PropsWithChildren> = ({ children }) => {
    return <StoreContext>{children}</StoreContext>;
};

const App: FC = (props) => {
    return (
        <Context>
            <Layout />
        </Context>
    );
};

export default App;
