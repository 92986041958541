import React from "react";
import styled from "styled-components";
import { Image } from "./UI";
import logosImage from "../assets/images/logos.png";

const SLogos = styled.div`
    position: fixed;
    top: 0;
    right: 0;

    width: 200px;
    height: 100px;

    ${(p) => p.theme.tabletQuery} {
        display: none;
    }
`;

const Logos = () => {
    return (
        <SLogos>
            <Image image={logosImage} contain />
        </SLogos>
    );
};

export default Logos;
