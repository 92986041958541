import { create } from "zustand";

interface IGallery {
    active: boolean;
    images: string[];
    selected: number;
    direction: -1 | 1;
    close(): void;
    open(images: string[], selected?: number): void;
    paginate(direction: 1 | -1): void;
}

const useGallery = create<IGallery>()((set, get) => ({
    active: false,
    images: [],
    selected: 0,
    direction: 1,
    close: () => set({ active: false, images: [], selected: 0 }),
    open: (images, selected = 0) => set({ images, selected, active: true }),
    paginate: (direction) =>
        set((state) => ({ selected: state.selected + direction, direction })),
}));

export default useGallery;
