import React, { useEffect } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { SVG, Text } from "./UI";
import icons from "../assets/icons";
import useHotspotPanel from "../store/HotspotPanel";
import { useLocation } from "react-router-dom";
import { Paths } from "../context/RouterContext";

const SHolder = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
`;

const SHotpostInfoPanel = styled(motion.div)`
    pointer-events: all;
    position: absolute;
    background-color: ${(p) => p.theme.colors.light};
    width: 486px;
    border-radius: 16px;
    padding: 24px;

    right: 10vw;
    top: 30vh;

    ${(p) => p.theme.tabletQuery} {
        left: 24px;
        right: 24px;
        width: auto;
    }
`;

const SClose = styled(motion.div)`
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    width: 24px;
    height: 24px;
`;

const HotspotInfoPanel = () => {
    const [active, description, close] = useHotspotPanel((state) => [
        state.active,
        state.description,
        state.close,
    ]);
    const location = useLocation();

    useEffect(() => {
        // Close panel if the user leaves the snail detials page.
        if (active && location.pathname != Paths.snailDetails) close();
    }, [location.pathname]);

    return (
        <AnimatePresence>
            {active && (
                <SHolder
                    exit={{ top: 50, opacity: 0 }}
                    initial={{ top: 50, opacity: 0 }}
                    animate={{ top: 0, opacity: 1 }}
                >
                    <SHotpostInfoPanel>
                        <SClose
                            whileHover={{ scale: 1.4 }}
                            onClick={() => close()}
                        >
                            <SVG image={icons.cross} color="primary" contain />
                        </SClose>
                        <Text color="dark" size="H5" lineHeight="150%">
                            {description}
                        </Text>
                    </SHotpostInfoPanel>
                </SHolder>
            )}
        </AnimatePresence>
    );
};

export default HotspotInfoPanel;
