import React from "react";
import styled, { css } from "styled-components";
import { IconButton, Image, SVG } from "./UI";
import icons from "../assets/icons";
import useGallery from "../store/Gallery";
import { motion, AnimatePresence, useIsPresent } from "framer-motion";
import { wrap } from "popmotion";
import { useIsMobileOrTablet } from "../hooks";

const SPhotoCarouselFullscreen = styled(motion.div)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #000000da;
    z-index: 1;
`;

const SRow = styled.div`
    position: absolute;
    height: 70vh;
    top: 50%;
    transform: translateY(-50%);
    width: 100vw;

    display: grid;
    grid-template-columns: repeat(4, 1fr);

    ${(p) => p.theme.tabletQuery} {
        grid-template-columns: 10px 1fr 1fr 10px;
        height: 50vh;
    }
`;

const SImageHolder = styled(motion.div)`
    position: relative;
    grid-column: 2 / 4;
`;

const SSideImageHolder = styled(motion.div)<{ isRight?: boolean }>`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    ${(p) =>
        p.isRight
            ? css`
                  right: -100%;
              `
            : css`
                  left: -100%;
              `}
`;

const SSideImageTint = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.9;
`;

const SControls = styled(motion.div)`
    position: absolute;
    width: 100%;
    bottom: -32px;
    transform: translateY(100%);
    display: grid;
    grid-gap: 16px;
    grid-template-columns: min-content min-content auto min-content;
`;

const SClose = styled.div`
    position: absolute;
    top: -32px;
    right: 32px;

    height: 64px;
    width: 64px;

    transform: translateY(-100%);
`;

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
};

const swipeConfidenceThreshold = 1000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

const PhotoCarouselFullscreen = () => {
    const isMobileOrTablet = useIsMobileOrTablet();
    const isPresent = useIsPresent();
    const [images, selected, active, close, direction, paginate] = useGallery(
        (s) => [
            s.images,
            s.selected,
            s.active,
            s.close,
            s.direction,
            s.paginate,
        ]
    );

    const selectedIndex = wrap(0, images.length, selected);
    const nextIndex = wrap(0, images.length, selected + 1);
    const prevIndex = wrap(0, images.length, selected - 1);

    return (
        <AnimatePresence>
            {active && (
                <SPhotoCarouselFullscreen
                    key="CarouselFullScreen"
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <SRow>
                        <SImageHolder
                            exit={{ scale: 0 }}
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{ bounce: 0.3, type: "spring" }}
                        >
                            <AnimatePresence initial={false} custom={direction}>
                                <Image
                                    contain
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: "visible",
                                    }}
                                    key={selectedIndex}
                                    custom={direction}
                                    variants={variants}
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    transition={{
                                        x: {
                                            type: "spring",
                                            stiffness: 300,
                                            damping: 30,
                                        },
                                        opacity: { duration: 0.2 },
                                    }}
                                    drag="x"
                                    dragConstraints={{ left: 0, right: 0 }}
                                    dragElastic={1}
                                    onDragEnd={(e, { offset, velocity }) => {
                                        const swipe = swipePower(
                                            offset.x,
                                            velocity.x
                                        );

                                        if (swipe < -swipeConfidenceThreshold)
                                            paginate(1);
                                        else if (
                                            swipe > swipeConfidenceThreshold
                                        )
                                            paginate(-1);
                                    }}
                                    image={images[selectedIndex]}
                                >
                                    <SSideImageHolder isRight>
                                        <Image image={images[nextIndex]} />
                                        <SSideImageTint />
                                    </SSideImageHolder>
                                    <SSideImageHolder>
                                        <Image image={images[prevIndex]} />
                                        <SSideImageTint />
                                    </SSideImageHolder>
                                </Image>
                            </AnimatePresence>

                            {!isMobileOrTablet && (
                                <SControls>
                                    <IconButton
                                        image={icons.arrowLeft}
                                        big
                                        onClick={() => paginate(-1)}
                                    />
                                    <IconButton
                                        image={icons.arrowRight}
                                        big
                                        onClick={() => paginate(1)}
                                    />
                                </SControls>
                            )}
                        </SImageHolder>

                        <SClose>
                            <SVG
                                style={{ cursor: "pointer" }}
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.3 },
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    transition: { duration: 0.3 },
                                }}
                                image={icons.cross}
                                color="light"
                                contain
                                onClick={() => close()}
                            />
                        </SClose>
                    </SRow>
                </SPhotoCarouselFullscreen>
            )}
        </AnimatePresence>
    );
};

export default PhotoCarouselFullscreen;
