import React from "react";
import styled from "styled-components";
import Babylon, { IBabylonProps } from "../../components/babylon/Babylon";

const SBabylonView = styled.div`
    position: relative;
    ${(p) => p.theme.desktopQuery} {
        height: 50vh;
    }
`;

const SContainer = styled.div`
    ${(p) => p.theme.desktopQuery} {
        position: absolute;

        height: 200%;
        width: 200%;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -52.5%);
    }

    ${(p) => p.theme.tabletQuery} {
        position: fixed;
        left: -24px;
        right: -24px;
        height: 800px;
        top: -100px;
    }

    ${(p) => p.theme.mobileQuery} {
        height: 500px;
    }
`;

const BabylonView: React.FC<IBabylonProps> = (props) => {
    return (
        <SBabylonView>
            <SContainer>
                <Babylon {...props} />
            </SContainer>
        </SBabylonView>
    );
};

export default BabylonView;
