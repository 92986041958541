import React, { useState, FC, useEffect } from "react";
import styled from "styled-components";
import {
    ArcRotateCamera,
    Vector3,
    HemisphericLight,
    Color3,
    MeshBuilder,
    Mesh,
    AbstractMesh,
    NodeMaterial,
    CubicEase,
    EasingFunction,
    Animation,
    Matrix,
} from "@babylonjs/core";
import SceneComponent from "babylonjs-hook";
import { Scene } from "@babylonjs/core/scene";
import { SceneLoader } from "@babylonjs/core/Loading";

import "@babylonjs/loaders";
import { Node } from "@babylonjs/core/node";
import { Dictionary } from "../../store";
import { IHotspot, ISnail } from "../../store/Snails";
import useHotspotPanel from "../../store/HotspotPanel";
import Loader from "../Loader";
import { logSelectedHotspot } from "../../connection/AnalyticsConnection";

const SBabylon = styled.div`
    height: 100%;
    width: 100%;
    position: relative;

    canvas:focus {
        outline: none;
    }

    * {
        height: 100%;
        width: 100%;
    }
`;

const SLoading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: min-content;
    height: min-content;
    transform: translateX(-40px);
`;

const hotspotParams = {
    dotColor: Color3.FromInts(51, 157, 74),
    dotSize: 0.5,
    antiAlias: 0.02,
    ghostValue: 0.5,
    ghostSize: 0.0,
    ghostMaxSize: 0.8,
    ringSize: 0.0,
    ringMaxSize: 0.8,
    ringThickness: 0.25,
    ringDotSize: 0.35,
    maxOpacity: 0.9,
};

let hotspots: any[] = [];
let hotspotStates: any = {};

export interface IBabylonProps {
    model: string;
    scaleFactor?: number;
    snailHotspots: Dictionary<IHotspot>;
    showSlug: boolean;
    showCoin: boolean;
    showHotspots: boolean;
    isMobileOrTablet: boolean;
    snail: ISnail;
}

const Babylon: FC<IBabylonProps> = ({
    model,
    scaleFactor,
    showSlug,
    showCoin,
    showHotspots,
    snailHotspots,
    isMobileOrTablet,
    snail,
}) => {
    const [{ slug, coin, camera, isPlaying }, set] = useState<{
        slug?: Node;
        coin?: Node;
        camera?: ArcRotateCamera;
        isPlaying: boolean;
    }>({
        isPlaying: true,
    });
    const openHotspotPanel = useHotspotPanel((state) => state.open);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        hotspots = Object.values(snailHotspots ?? {}).map((hs) => ({
            id: hs.id,
            name: hs.name,
            position: new Vector3(hs.position.x, hs.position.y, hs.position.z),
            forward: new Vector3(-1, 0, 0),
            angleMin: -0.75,
            angleMax: -0.6,
            selected: false,
            toastVersion: 0,
            description: hs.description,
        }));
    }, [snailHotspots]);

    useEffect(() => {
        hotspots.forEach((hs) => {
            if (hs.mesh) hs.mesh.isVisible = showHotspots;
        });
    }, [showHotspots]);

    const animateCamera = (): void => {
        if (!camera) return;
        set((p) => ({ ...p, isPlaying: true }));
        camera.radius = 50;
        camera.alpha = 3.31;
        camera.beta = 1.11;
    };

    const onSceneReady = async (scene: Scene) => {
        // Remove background.
        {
            scene.autoClear = false;
        }

        // This creates and positions a free camera (non-mesh)
        const newCamera = new ArcRotateCamera(
            "camera1",
            Math.PI / 2,
            Math.PI / 3,
            45,
            new Vector3(0, 10, 0),
            scene
        );

        // Camera and light configuration.
        {
            set((p) => ({ ...p, camera: newCamera }));

            newCamera.lowerRadiusLimit = 35;
            newCamera.upperRadiusLimit = 80;
            newCamera.panningDistanceLimit = 10;
            newCamera.allowUpsideDown = false;
            newCamera.fov = (60 * Math.PI) / 180;

            if (isMobileOrTablet) {
                newCamera.lowerRadiusLimit = 10;
                newCamera.radius = 55;
            }

            const canvas = scene.getEngine().getRenderingCanvas();
            // This attaches the camera to the canvas
            newCamera.attachControl(canvas, true);
            // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
            const light = new HemisphericLight(
                "light",
                new Vector3(0, 1, 0),
                scene
            );
            light.intensity = 1.8;

            new HemisphericLight("light-bottom", new Vector3(0, -1, 0), scene);
        }

        // Hotspots.
        {
            // Create hotspot material.
            const material = new NodeMaterial("hotspot", scene, {
                emitComments: false,
            });
            await material.loadAsync(
                "https://patrickryanms.github.io/BabylonJStextures/Demos/splitScreen/shaders/hotspotShader.json"
            );
            material.build(false);
            try {
                //@ts-ignore
                material.getBlockByName("dotColor").value =
                    hotspotParams.dotColor;
                //@ts-ignore
                material.getBlockByName("dotSize").value =
                    hotspotParams.dotSize;
                //@ts-ignore
                material.getBlockByName("antiAlias").value =
                    hotspotParams.antiAlias;
                //@ts-ignore
                material.getBlockByName("ghostValue").value =
                    hotspotParams.ghostValue;
                //@ts-ignore
                material.getBlockByName("ghostSize").value =
                    hotspotParams.ghostSize;
                //@ts-ignore
                material.getBlockByName("ringSize").value =
                    hotspotParams.ringSize;
                //@ts-ignore
                material.getBlockByName("ringThickness").value =
                    hotspotParams.ringThickness;
                //@ts-ignore
                material.getBlockByName("maxOpacity").value =
                    hotspotParams.maxOpacity;
            } catch {}

            // create hotspots
            for (let child of hotspots) {
                child.mesh = MeshBuilder.CreatePlane(
                    child.name,
                    { size: 4 },
                    scene
                );
                child.mesh.position = child.position;
                child.mesh.billboardMode = AbstractMesh.BILLBOARDMODE_ALL;

                // Material
                try {
                    child.mesh.material = material.clone(
                        "hotspot_" + child.name.split("_")[1]
                    );
                    //@ts-ignore
                    child.mesh.material.getBlockByName("hotspotVector").value =
                        child.forward;
                    //@ts-ignore
                    child.mesh.material.getBlockByName("angleMin").value =
                        child.angleMin;
                    //@ts-ignore
                    child.mesh.material.getBlockByName("angleMax").value =
                        child.angleMax;
                } catch {}
            }

            const hotSpotAnim: any = {};
            const prepHotspotAnimation = () => {
                // set up keys for all animations
                hotSpotAnim.hoverKeys = [
                    { frame: 0, value: 0.0 },
                    { frame: 15, value: hotspotParams.ghostMaxSize + 0.1 },
                    { frame: 25, value: hotspotParams.ghostMaxSize },
                ];

                hotSpotAnim.unhoverKeys = [
                    { frame: 0, value: hotspotParams.ghostMaxSize },
                    { frame: 10, value: hotspotParams.ghostMaxSize + 0.1 },
                    { frame: 25, value: 0.0 },
                ];

                hotSpotAnim.clickRingKeys = [
                    { frame: 0, value: 0.0 },
                    { frame: 15, value: hotspotParams.ringMaxSize + 0.1 },
                    { frame: 25, value: hotspotParams.ringMaxSize },
                ];

                hotSpotAnim.clickDotKeys = [
                    { frame: 0, value: hotspotParams.dotSize },
                    { frame: 15, value: hotspotParams.ringDotSize - 0.1 },
                    { frame: 25, value: hotspotParams.ringDotSize },
                ];

                hotSpotAnim.clickGhostKeys = [
                    { frame: 0, value: hotspotParams.ghostMaxSize },
                    { frame: 10, value: 0.0 },
                ];

                hotSpotAnim.releaseRingKeys = [
                    { frame: 0, value: hotspotParams.ringMaxSize },
                    { frame: 15, value: hotspotParams.ringMaxSize + 0.1 },
                    { frame: 35, value: 0.0 },
                ];

                hotSpotAnim.releaseDotKeys = [
                    { frame: 0, value: hotspotParams.ringDotSize },
                    { frame: 10, value: hotspotParams.ringDotSize },
                    { frame: 35, value: hotspotParams.dotSize },
                ];

                hotSpotAnim.releaseGhostKeys = [
                    { frame: 0, value: 0.0 },
                    { frame: 10, value: 0.0 },
                    { frame: 25, value: hotspotParams.ghostMaxSize },
                ];

                // set up easing function and easing mode
                hotSpotAnim.easingFunction = new CubicEase();
                hotSpotAnim.easingMode = EasingFunction.EASINGMODE_EASEINOUT;
                hotSpotAnim.easingFunction.setEasingMode(
                    hotSpotAnim.easingMode
                );

                hotSpotAnim.hover = new Animation(
                    "hover",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );
                hotSpotAnim.unhover = new Animation(
                    "unhover",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );
                hotSpotAnim.clickRing = new Animation(
                    "clickRing",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );
                hotSpotAnim.clickDot = new Animation(
                    "clickDot",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );
                hotSpotAnim.clickGhost = new Animation(
                    "clickGhost",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );
                hotSpotAnim.releaseRing = new Animation(
                    "releaseRing",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );
                hotSpotAnim.releaseDot = new Animation(
                    "releaseDot",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );
                hotSpotAnim.releaseGhost = new Animation(
                    "releaseGhost",
                    "value",
                    60,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CONSTANT
                );

                // set up easing
                hotSpotAnim.hover.setEasingFunction(hotSpotAnim.easingFunction);
                hotSpotAnim.unhover.setEasingFunction(
                    hotSpotAnim.easingFunction
                );
                hotSpotAnim.clickRing.setEasingFunction(
                    hotSpotAnim.easingFunction
                );
                hotSpotAnim.clickDot.setEasingFunction(
                    hotSpotAnim.easingFunction
                );
                hotSpotAnim.clickGhost.setEasingFunction(
                    hotSpotAnim.easingFunction
                );
                hotSpotAnim.releaseRing.setEasingFunction(
                    hotSpotAnim.easingFunction
                );
                hotSpotAnim.releaseDot.setEasingFunction(
                    hotSpotAnim.easingFunction
                );
                hotSpotAnim.releaseGhost.setEasingFunction(
                    hotSpotAnim.easingFunction
                );

                // append keys to animation
                hotSpotAnim.hover.setKeys(hotSpotAnim.hoverKeys);
                hotSpotAnim.unhover.setKeys(hotSpotAnim.unhoverKeys);
                hotSpotAnim.clickRing.setKeys(hotSpotAnim.clickRingKeys);
                hotSpotAnim.clickDot.setKeys(hotSpotAnim.clickDotKeys);
                hotSpotAnim.clickGhost.setKeys(hotSpotAnim.clickGhostKeys);
                hotSpotAnim.releaseRing.setKeys(hotSpotAnim.releaseRingKeys);
                hotSpotAnim.releaseDot.setKeys(hotSpotAnim.releaseDotKeys);
                hotSpotAnim.releaseGhost.setKeys(hotSpotAnim.releaseGhostKeys);
            };
            prepHotspotAnimation();

            const playHotspotHover = (material: any, hover: any) => {
                let activeGhostSize = material.getBlockByName("ghostSize");
                if (hover) {
                    scene.beginDirectAnimation(
                        activeGhostSize,
                        [hotSpotAnim.hover],
                        0,
                        hotSpotAnim.hoverKeys[hotSpotAnim.hoverKeys.length - 1]
                            .frame,
                        false,
                        1
                    );
                } else {
                    scene.beginDirectAnimation(
                        activeGhostSize,
                        [hotSpotAnim.unhover],
                        0,
                        hotSpotAnim.hoverKeys[hotSpotAnim.hoverKeys.length - 1]
                            .frame,
                        false,
                        1
                    );
                }
            };

            const playHotspotClick = (material: any, action: any) => {
                let activeGhostSize = material.getBlockByName("ghostSize");
                let activeRingSize = material.getBlockByName("ringSize");
                let activeDotSize = material.getBlockByName("dotSize");
                if (action === "click") {
                    scene.beginDirectAnimation(
                        activeGhostSize,
                        [hotSpotAnim.clickGhost],
                        0,
                        hotSpotAnim.clickGhostKeys[
                            hotSpotAnim.clickGhostKeys.length - 1
                        ].frame,
                        false,
                        1
                    );
                    scene.beginDirectAnimation(
                        activeRingSize,
                        [hotSpotAnim.clickRing],
                        0,
                        hotSpotAnim.clickRingKeys[
                            hotSpotAnim.clickRingKeys.length - 1
                        ].frame,
                        false,
                        1
                    );
                    scene.beginDirectAnimation(
                        activeDotSize,
                        [hotSpotAnim.clickDot],
                        0,
                        hotSpotAnim.clickDotKeys[
                            hotSpotAnim.clickDotKeys.length - 1
                        ].frame,
                        false,
                        1
                    );
                } else {
                    scene.beginDirectAnimation(
                        activeRingSize,
                        [hotSpotAnim.releaseRing],
                        0,
                        hotSpotAnim.releaseRingKeys[
                            hotSpotAnim.releaseRingKeys.length - 1
                        ].frame,
                        false,
                        1
                    );
                    scene.beginDirectAnimation(
                        activeDotSize,
                        [hotSpotAnim.releaseDot],
                        0,
                        hotSpotAnim.releaseDotKeys[
                            hotSpotAnim.releaseDotKeys.length - 1
                        ].frame,
                        false,
                        1
                    );
                }
            };

            const clearHotspots = () => {
                for (let child of hotspots) {
                    if (child.selected === true) {
                        playHotspotClick(child?.mesh?.material, "reset");
                        child.selected = false;
                    }
                }
            };

            // Ray
            {
                const ray: any = {};
                const raycast = (action: any) => {
                    ray.pickingRay = scene.createPickingRay(
                        scene.pointerX,
                        scene.pointerY,
                        Matrix.Identity(),
                        newCamera
                    );

                    ray.hit = scene.pickWithRay(ray.pickingRay);

                    if (ray.hit.pickedMesh) {
                        if (
                            hotspotStates.active === undefined ||
                            hotspotStates.active === null ||
                            action !== "hover"
                        ) {
                            for (let child of hotspots) {
                                if (child.name === ray.hit.pickedMesh.name) {
                                    hotspotStates.active = child;
                                    if (
                                        action === "hover" &&
                                        hotspotStates.active.selected === false
                                    ) {
                                        playHotspotHover(
                                            hotspotStates.active.mesh.material,
                                            true
                                        );
                                    }
                                    if (action === "click") {
                                        if (
                                            hotspotStates.active.selected ===
                                            false
                                        ) {
                                            clearHotspots();
                                            playHotspotClick(
                                                hotspotStates.active.mesh
                                                    .material,
                                                action
                                            );
                                            openHotspotPanel(child.description);

                                            logSelectedHotspot(
                                                snail.name,
                                                child.name
                                            );
                                            setTimeout(clearHotspots, 1000);

                                            hotspotStates.active.selected =
                                                true;
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        if (
                            hotspotStates.active &&
                            hotspotStates.active !== null
                        ) {
                            if (
                                action === "hover" &&
                                hotspotStates.active.selected === false
                            ) {
                                playHotspotHover(
                                    hotspotStates.active.mesh.material,
                                    false
                                );
                            }
                            hotspotStates.active = null;
                        }
                    }
                };

                scene.onPointerMove = () => {
                    raycast("hover");
                };

                scene.onPointerUp = () => {
                    raycast("click");
                    hotspotStates.mouseDown = false;
                };
            }
        }

        // Import Model
        {
            const importModel = (url: string): Promise<Node[]> =>
                new Promise((resolve, reject) => {
                    try {
                        SceneLoader.ImportMesh("", url, "", scene, (meshes) => {
                            const multiply = 1000 * (scaleFactor ?? 1);

                            meshes[0].scaling.x *= multiply;
                            meshes[0].scaling.y *= multiply;
                            meshes[0].scaling.z *= multiply;

                            resolve(meshes[0].getChildren());
                        });
                    } catch {
                        reject();
                    }
                });

            importModel(model).then((meshes) => {
                meshes.forEach((mesh) => {
                    if (mesh?.name?.includes("coin"))
                        set((p) => ({ ...p, coin: mesh }));
                    if (mesh?.name?.includes("slug"))
                        set((p) => ({ ...p, slug: mesh }));

                    setIsLoading(false);
                    animateCamera();
                });
            });
        }
    };

    const onRender = (scene: Scene) => {
        if (!camera) return;

        if (isPlaying) {
            if (camera.radius > 15) camera.radius -= 0.5;
            else set((p) => ({ ...p, isPlaying: false }));

            if (camera.alpha > 1.653) camera.alpha -= 0.025;
            if (camera.beta > 1.212) camera.beta -= 0.025;
        }
    };

    useEffect(() => {
        if (slug) slug.setEnabled(showSlug);
    }, [showSlug, slug]);

    useEffect(() => {
        if (coin) coin.setEnabled(showCoin);
    }, [showCoin, coin]);

    return (
        <SBabylon>
            <SceneComponent
                antialias
                adaptToDeviceRatio
                onSceneReady={onSceneReady}
                onRender={onRender}
                id="my-canvas"
            />

            {isLoading && (
                <SLoading>
                    <Loader />
                </SLoading>
            )}
        </SBabylon>
    );
};

export default Babylon;
