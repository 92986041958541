import { create } from "zustand";

interface HotspotPanel {
    active: boolean;
    description: string;
    close(): void;
    open(description: string): void;
}

const useHotspotPanel = create<HotspotPanel>()((set, get) => ({
    active: false,
    description: "",
    close: () => set({ active: false, description: "" }),
    open: (description) => set({ description, active: true }),
}));

export default useHotspotPanel;
