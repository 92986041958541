import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import BackButton from "./BackButton";
import Controls from "./Controls";
import { IControls } from "./Controls/logic";

const SMobileTopbar = styled(motion.div)`
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-gap: 16px;
    padding: 0 32px;
    margin-top: 60px;
    align-items: center;
    position: fixed;
    z-index: 1;
`;

const MobileTopbar: React.FC<{ controls: IControls }> = ({ controls }) => {
    return (
        <SMobileTopbar>
            <BackButton />
            <div />
            <Controls {...controls} />
        </SMobileTopbar>
    );
};

export default MobileTopbar;
