import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// Theme.
import { ThemeProvider } from "styled-components";
import Theme, { MobileTheme, TabletTheme } from "./theme";
import { useIsMobile, useIsTablet } from "./hooks";
import { AnalyticsConnectionContext } from "./connection/AnalyticsConnection";
import { RouterContext } from "./context/RouterContext";

function Container() {
    const [theme, setTheme] = useState(Theme);
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    useEffect(() => {
        setTheme(isMobile ? MobileTheme : isTablet ? TabletTheme : Theme);
    }, [isMobile, isTablet]);

    return (
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    );
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <Container />
    </React.StrictMode>
);
