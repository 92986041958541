import styled from "styled-components";
import { Text, Image, Button } from "../components/UI";
import { motion } from "framer-motion";
import { useIsMobile, useIsMobileOrTablet, useRouter } from "../hooks";
import BackButton from "./SnailDetails/BackButton";

import footerImage from "../assets/images/footer.png";
import footerMobileImage from "../assets/images/footer-mobile.png";
import { Paths } from "../context/RouterContext";

const SAbout = styled(motion.div)`
    display: grid;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
`;

const SContent = styled.div`
    display: flex;
    padding: 64px 96px;
    flex-direction: column;
    gap: 36px;
    max-height: 100%;
    overflow: hidden;

    ${(p) => p.theme.tabletQuery} {
        padding: 32px;
    }
`;

const STitle = styled(motion.div)`
    display: grid;
    width: min-content;
    margin-top: 32px;

    ${(p) => p.theme.tabletQuery} {
        margin-top: 0;
    }
`;

const SDescription = styled(motion.div)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    overflow: scroll;
`;

const SStart = styled.div`
    width: 100%;
    display: grid;
    place-items: center;
    * {
        width: 250px;
    }
`;

const SFooter = styled(motion.div)`
    height: 142px;
    ${(p) => p.theme.tabletQuery} {
        height: 300px;
    }
    height: 300px;
`;

const vAbout = {
    hidden: { transition: { staggerChildren: -0.05 } },
    show: { transition: { staggerChildren: 0.05 } },
};

const vItem = {
    hidden: { x: "-840px" },
    show: { x: 0 },
};

function About() {
    // const isMobileOrTablet = useIsMobileOrTablet();
    const isMobileOrTablet = true; // Weird client request.
    const isMobile = useIsMobile();
    const router = useRouter();

    return (
        <SAbout variants={vAbout} exit="hidden" initial="hidden" animate="show">
            <SContent>
                {/* <BackButton /> */}
                <STitle
                    exit={{ y: -300 }}
                    animate={{ y: 0 }}
                    initial={{ y: -300 }}
                    style={{ marginLeft: isMobile ? "16px" : "0" }}
                >
                    <Text
                        color="primary"
                        weight="bold"
                        oneline
                        size={isMobileOrTablet ? "H25" : "H1"}
                    >
                        Established and Exotic Snails
                    </Text>
                    <div
                        style={{
                            display: "grid",
                            gridAutoFlow: "column",
                        }}
                    >
                        <Text
                            color="primary"
                            weight="bold"
                            oneline
                            size={isMobileOrTablet ? "H3" : "H25"}
                        >
                            of South Australia
                        </Text>
                        <Text
                            style={{
                                width: "min-content",
                                marginLeft: "auto",
                            }}
                            size={isMobileOrTablet ? "H3" : "H25"}
                            oneline
                            color="dark"
                        >
                            3D Gallery
                        </Text>
                    </div>
                </STitle>
                <SDescription variants={vItem}>
                    <Text color="dark" size="H5" lineHeight="150%">
                        Welcome to the Grain Producers South Australia (GPSA) 3D
                        Gallery of Established and Exotic snails. Designed
                        exclusively for GPSA with support from the Department of
                        Agriculture, Fisheries and Forestry (DAFF), this
                        platform is your gateway to a deeper understanding of
                        the different species of snails in our region.
                        <br />
                        <br />
                        At Grain Producers South Australia, we believe that
                        knowledge is a powerful tool in safeguarding our crops
                        and ensuring sustainable agriculture practices. We hope
                        you find this resource useful to assist you in
                        identifying and learning more about the species which
                        are already established in SA, and those which pose a
                        potential future threat.
                        <br />
                        <br />
                    </Text>
                    <Text color="primary" weight="bold" size="H3">
                        About The Project
                    </Text>
                    <Text color="dark" size="H5" lineHeight="150%">
                        <b>Is your grain Market Ready?</b>
                        <br />
                        <br />
                        South Australia has an excellent reputation for
                        providing premium agricultural products and working
                        closely with trading partners to ensure their needs are
                        met. By continuing to uphold high grain hygiene
                        standards, South Australian grain growers will have
                        access to a large range of markets across the world for
                        their grain.
                        <br />
                        <br />
                        The Market Ready campaign is an initiative of the South
                        Australian Grain Market Access Group. It is delivered by
                        Grain Producers SA with the support of the Australian
                        Government’s Agricultural Trade and Market Access
                        Cooperation (ATMAC) Program.
                    </Text>
                </SDescription>

                <SStart>
                    <Button big onClick={() => router.setPath(Paths.home)}>
                        Start
                    </Button>
                </SStart>

                <SFooter variants={vItem}>
                    <Image
                        image={isMobile ? footerMobileImage : footerImage}
                        contain
                        imagePosition="center"
                    />
                </SFooter>
            </SContent>
        </SAbout>
    );
}

export default About;
