import React, { useState } from "react";
import styled from "styled-components";
import { IconButton, Text, SVG } from "../components/UI";
import SnailCarousel from "../components/SnailCarousel";
import icons from "../assets/icons";
import { motion } from "framer-motion";
import { useIsMobile, useIsMobileOrTablet, useRouter } from "../hooks";
import PageControls from "../components/PageControls";
import { Paths } from "../context/RouterContext";
import Logos from "../components/Logos";

const SHome = styled(motion.div)`
    display: grid;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
`;

const SContent = styled.div`
    display: grid;
    /* grid-gap: 48px; */
`;

const SControls = styled(motion.div)`
    margin-top: 48px;
    height: 72px;
    display: grid;
    grid-template-columns: min-content min-content min-content auto min-content;
    grid-gap: 16px;

    margin-left: 96px;
    margin-right: 160px;
`;

const SMobileControls = styled(motion.div)`
    display: grid;
    grid-template-columns: min-content auto min-content min-content;
    margin: 32px;
`;

const STitle = styled(motion.div)`
    display: grid;
    width: min-content;
    margin-bottom: 48px;
`;

const SAbout = styled(motion.div)`
    /* position: fixed; */
    /* bottom: 34px; */
    /* left: 105px; */
    padding-left: 105px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    margin-top: 24px;
    width: min-content;

    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }

    ${(p) => p.theme.tabletQuery} {
        /* left: 24px; */
        padding-left: 24px;
    }
`;

const vButtons = {
    hidden: { transition: { staggerChildren: -0.05 } },
    show: { transition: { staggerChildren: 0.05 } },
};

const vButtonsItem = {
    hidden: { y: 300 },
    show: { y: 0 },
};

function Home() {
    const [next, setNext] = useState(0);
    const [prev, setPrev] = useState(0);
    const isMobileOrTablet = useIsMobileOrTablet();
    const isMobile = useIsMobile();
    const router = useRouter();

    return (
        <SHome>
            <Logos />
            <SContent>
                <STitle
                    exit={{ y: -300 }}
                    animate={{ y: 0 }}
                    initial={{ y: -300 }}
                    style={{ marginLeft: isMobile ? "16px" : "96px" }}
                >
                    <Text
                        color="primary"
                        weight="bold"
                        oneline
                        size={isMobileOrTablet ? "H25" : "H1"}
                    >
                        Established and Exotic Snails
                    </Text>
                    <div
                        style={{
                            display: "grid",
                            gridAutoFlow: "column",
                        }}
                    >
                        <Text
                            color="primary"
                            weight="bold"
                            oneline
                            size={isMobileOrTablet ? "H3" : "H25"}
                        >
                            of South Australia
                        </Text>
                        <Text
                            style={{
                                width: "min-content",
                                marginLeft: "auto",
                            }}
                            size={isMobileOrTablet ? "H3" : "H25"}
                            oneline
                            color="dark"
                        >
                            3D Gallery
                        </Text>
                    </div>
                </STitle>

                <SnailCarousel
                    initial={{ x: "80vw", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "80vw", opacity: 0 }}
                    next={next}
                    prev={prev}
                />

                {isMobile ? (
                    <SMobileControls
                        variants={vButtonsItem}
                        exit="hidden"
                        initial="hidden"
                        animate="show"
                    >
                        <Text size="H4" color="dark" oneline italic>
                            Touch a snail to view in 3D
                        </Text>
                        <div />
                        <PageControls carouselId="mobileCarousel" />
                    </SMobileControls>
                ) : (
                    <SControls
                        variants={vButtons}
                        exit="hidden"
                        initial="hidden"
                        animate="show"
                    >
                        <IconButton
                            variants={vButtonsItem}
                            image={icons.arrowLeft}
                            onClick={() => setPrev((p) => ++p)}
                            big
                        />
                        <IconButton
                            variants={vButtonsItem}
                            image={icons.arrowRight}
                            onClick={() => setNext((p) => ++p)}
                            big
                        />
                        <Text
                            variants={vButtonsItem}
                            size="H4"
                            color="dark"
                            oneline
                            middle
                            italic
                        >
                            Click on a snail to view in 3D
                        </Text>
                        <div />
                        <PageControls carouselId="mobileCarousel" />
                    </SControls>
                )}

                <SAbout onClick={() => router.setPath(Paths.about)}>
                    <SVG
                        style={{ width: "24px" }}
                        image={icons.about}
                        color="primary"
                        contain
                    />
                    <Text color="primary" oneline size="H4">
                        About the Project
                    </Text>
                </SAbout>
            </SContent>
        </SHome>
    );
}

export default Home;
