import { create } from "zustand";
import { Dictionary, IBase } from "./";

export interface IHotspot extends IBase {
    name: string;
    description: string;
    position: {
        x: number;
        y: number;
        z: number;
    };
}

export interface ISnail extends IBase {
    description: string;
    photoGallery: string[]; // URLs.
    thumbnail: string;
    model: string;
    scaleFactor?: number;
    sizes: {
        height: string;
        width: string;
    };
    factSheet: string;
    hotspots?: Dictionary<IHotspot>;
    category: "not-yet-present" | "established";
    scientificName: string;
}

interface ISnailState {
    snails: Dictionary<ISnail>;
    downloading: boolean;
    setSnails: (snails: Dictionary<ISnail>) => void;
}

const useSnailStore = create<ISnailState>()((set) => ({
    snails: {},
    downloading: true,
    setSnails: (snails) =>
        set((state) => ({ ...state, snails, downloading: false })),
}));

export default useSnailStore;
