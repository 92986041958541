import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Text, SVG } from "../../components/UI";
import icons from "../../assets/icons";
import { useIsMobileOrTablet, useRouter } from "../../hooks";
import { vInfoPanelItem } from "./InfoPanel";
import { Paths } from "../../context/RouterContext";

const SBack = styled(motion.div)`
    display: grid;
    grid-template-columns: 48px auto;
    grid-gap: 16px;
    cursor: pointer;
    width: min-content;

    ${(p) => p.theme.tabletQuery} {
        grid-template-columns: 24px auto;
    }
`;

const BackButton = () => {
    const isMobileOrTablet = useIsMobileOrTablet();
    const router = useRouter();

    console.log("HERE: ", window.location.pathname, Paths.about);
    const color =
        isMobileOrTablet && window.location.pathname != Paths.about
            ? "light"
            : "primary";

    return (
        <SBack
            whileHover={{
                scale: 1.1,
            }}
            whileTap={{
                scale: 0.9,
            }}
            transition={{
                scale: { duration: 0.3, type: "spring" },
            }}
            onClick={() => router.setPath(Paths.home)}
            variants={vInfoPanelItem}
        >
            <SVG image={icons.longArrowLeft} color={color} contain />
            <Text size="H4" weight="bold" color={color} middle>
                Back
            </Text>
        </SBack>
    );
};

export default BackButton;
