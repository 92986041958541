import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useIsMobileOrTablet } from "../../hooks";
import useControls from "./Controls/logic";
import { ISnail } from "../../store/Snails";
import MobileTopbar from "./MobileTopbar";
import Controls from "./Controls";
import BabylonView from "./BabylonView";

const SGreenPanel = styled(motion.div)`
    position: absolute;

    background-color: ${(p) => p.theme.colors.primary};
    background: linear-gradient(
        180deg,
        ${(p) => p.theme.gradients.primary.a} 0%,
        ${(p) => p.theme.gradients.primary.b} 100%
    );

    /* width: 496px;
    height: 769px; */

    ${(p) => p.theme.desktopQuery} {
        width: 30vw;
        height: 85vh;

        bottom: 0;
        right: 0;
    }

    border-radius: 96px 0 0 0;

    ${(p) => p.theme.tabletQuery} {
        width: 100vw;
        height: 420px;
        border-radius: 0 0 0 36px;

        position: relative;
        margin-bottom: 240px;

        ${(p) => p.theme.mobileQuery} {
            height: 323px;
            margin-bottom: 77px;
        }
    }
`;

const SSnailView = styled(motion.div)`
    position: absolute;
    ${(p) => p.theme.desktopQuery} {
        width: 50vw;
        height: 50vh;
        top: 15%;
        left: 0;
        transform: translateX(-60%);
    }

    ${(p) => p.theme.tabletQuery} {
        width: auto;
        left: 20px;
        right: 20px;

        height: 389px;
        bottom: -144px;

        ${(p) => p.theme.mobileQuery} {
            height: 259px;
            bottom: -77px;
        }
    }
`;

const GreenPanel: React.FC<{ snail: ISnail }> = ({ snail }) => {
    const isMobileOrTablet = useIsMobileOrTablet();
    const controls = useControls();

    return (
        <SGreenPanel
            initial={
                isMobileOrTablet ? { y: "-100%" } : { x: "100%", y: "100%" }
            }
            exit={isMobileOrTablet ? { y: "-100%" } : { x: "100%", y: "100%" }}
            animate={{ x: 0, y: 0 }}
        >
            <SSnailView
                initial={isMobileOrTablet ? {} : { scale: 0, x: 0, y: "100%" }}
                exit={isMobileOrTablet ? {} : { scale: 0, x: 0, y: "100%" }}
                animate={
                    isMobileOrTablet
                        ? { x: 0, y: 0 }
                        : {
                              scale: 1,
                              x: "-60%",
                              y: 0,
                          }
                }
                transition={{
                    delay: 0.3,
                    type: "spring",
                    bounce: 0.3,
                }}
            >
                <AnimatePresence>
                    <motion.div
                        key="babylonMotion"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        <BabylonView
                            model={
                                snail?.model ??
                                "https://firebasestorage.googleapis.com/v0/b/snail-3d.appspot.com/o/models%2Fsnails-with-coins%2Fchocolate.glb?alt=media"
                            }
                            scaleFactor={snail.scaleFactor}
                            showSlug={controls.slug.value}
                            showCoin={controls.coin.value}
                            showHotspots={controls.hotspots.value}
                            snailHotspots={snail?.hotspots ?? {}}
                            isMobileOrTablet={isMobileOrTablet ?? false}
                            snail={snail}
                        />
                    </motion.div>
                </AnimatePresence>
            </SSnailView>

            {isMobileOrTablet ? (
                <MobileTopbar controls={controls} />
            ) : (
                <Controls {...controls} />
            )}
        </SGreenPanel>
    );
};

export default GreenPanel;
